import React from 'react'
import PropTypes from 'prop-types'
import NextHead from 'next/head'
import { string } from 'prop-types'
import {
  fbDescription,
  twitterDescription,
  defaultTitle,
  defaultDescription,
  defaultOGImage,
  defaultOGURL,
  defaultTwitterImage,
  googleSearchConsoleTags
} from '../constants/HeadInformation'

const gtmScript =
  process.env.NODE_ENV !== 'development'
    ? {
      __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-M98N8MC');
            `,
    }
    : { __html: `` }

const segScript =
  process.env.NODE_ENV !== 'dev'
    ? {
      __html: `
      !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
      analytics.load("esDICEljuUmDAP6PjZGgZNYaIM2oJ4hg");
      analytics.page();
      }}();
`,
    }
    : { __html: `` }

const Head = ({ title, twitterImage, description, url, ogImage }) => (
  <NextHead>
    <meta charSet="UTF-8" />
    <title>{title || defaultTitle} </title>
    <meta name="description" content={description || defaultDescription} />
    {/* <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
    {/* INFO: should prevent iPhone from zooming in on input fields */}
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
    <link rel="icon" sizes="192x192" href="/static/touch-icon.png" />
    <link rel="apple-touch-icon" href="/static/touch-icon.png" />
    <link rel="mask-icon" href="/static/favicon-mask.svg" color="#ff696d" />

    <link rel="icon" href="/static/favicon.ico" />

    <meta name="google-site-verification" content="o8FHKiidYYQht2B1rwKr2OT8skZYc8Lkknyi-yG92V0" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@thresholdmvmt" />
    <meta name="twitter:title" content={title || defaultTitle} />
    <meta name="twitter:description" content={twitterDescription || twitterDescription} />
    <meta name="twitter:image" content={twitterImage || defaultTwitterImage} />

    <meta property="og:url" content={defaultOGURL} />
    <meta property="og:title" content={title || defaultTitle} />
    <meta property="og:type" content="website" />
    <meta property="og:image" content={defaultOGImage} />
    <meta property="og:description" content={description || defaultDescription} />
    <meta property="fb:app_id" content="1531051710526317" />
    {/* google search console tag, valid only landslide2020.org */}
    <meta name="google-site-verification" content={googleSearchConsoleTags} />
    <meta name="fo-verify" content="9b31673d-0ff7-4e1f-ab22-717dee176126"></meta>
    <script dangerouslySetInnerHTML={gtmScript} />
    <script dangerouslySetInnerHTML={segScript} />
  </NextHead>
)

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  ogImage: PropTypes.string,
  twitterImage: PropTypes.string,
}

export default Head
