import { ROOT_DOMAIN } from '../globals'

export const fbDescription = 'Start a Landslide to crush Trump and the GOP. Track your impact. Multiply your power. '
export const twitterDescription = ' Start a Landslide to crush Trump and the GOP. Track your impact. Multiply your power. '

export const defaultTitle = 'Landslide 2020'
export const defaultDescription = fbDescription
export const defaultOGURL = 'https://landslide2020.org'
export const defaultOGImage = ROOT_DOMAIN + '/static/og_vote-early-sm.jpg'
export const defaultTwitterImage = ROOT_DOMAIN + '/static/og_vote-early-sm.jpg'
export const googleSearchConsoleTags = 'M-va6Kc3TPalH7WgCPMwG_wLkUXX42SO3iei7hfyKHc'